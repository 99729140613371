import request from '@/utils/request'
// 获取商铺列表
export function WeGetShopPage(query) {
  return request({
    url: 'Shop/WeGetShopPage',
    method: 'get',
    params: query,
  })
}
// 获取商铺类型列表
export function WxGetShopTypeList(query) {
  return request({
    url: 'Shop/WxGetShopTypeList',
    method: 'get',
    params: query,
  })
}
// 获取商品列表
export function WxGetGoodsPage(query) {
  return request({
    url: 'Goods/WxGetGoodsPage',
    method: 'get',
    params: query,
  })
}
// 获取商品类型列表
export function WxGetGoodsSortList(query) {
  return request({
    url: 'GoodsSort/WxGetGoodsSortList',
    method: 'get',
    params: query,
  })
}
// 获取商品详情
export function WxGetMyShop(query) {
  return request({
    url: 'Shop/WxGetMyShop',
    method: 'get',
    params: query,
  })
}
// 生成订单
export function WeGenerateOrder(data) {
  return request({
    url: 'ShopOrder/WeGenerateOrder',
    method: 'post',
    data,
  })
}
// 提交订单
export function WePlaceOrder(data) {
  return request({
    url: 'ShopOrder/WePlaceOrder',
    method: 'post',
    data,
  })
}
// 撤销订单
export function WeRevokeOrder(data) {
  return request({
    url: 'ShopOrder/WeRevokeOrder',
    method: 'post',
    data,
  })
}
// 删除订单
export function WeDeleteOrder(data) {
  return request({
    url: 'ShopOrder/WeDeleteOrder',
    method: 'post',
    data,
  })
}
// 增加订单商品
export function WeSaveOrderService(data) {
  return request({
    url: 'ShopOrder/WeSaveOrderService',
    method: 'post',
    data,
  })
}
// 删除订单商品
export function WeDeleteOrderGoods(data) {
  return request({
    url: 'ShopOrder/WeDeleteOrderGoods',
    method: 'post',
    data,
  })
}
// 获取订单内列表
export function WeGetOrderGoodsList(query) {
  return request({
    url: 'ShopOrder/WeGetOrderGoodsList',
    method: 'get',
    params: query,
  })
}
// 获取我的订单列表，居安端居民使用
export function WeGetMyOrderPage(query) {
  return request({
    url: 'ShopOrder/WeGetMyOrderPage',
    method: 'get',
    params: query,
  })
}
// 获取我的订单详情
export function WeGetMyOrder(query) {
  return request({
    url: 'ShopOrder/WeGetMyOrder',
    method: 'get',
    params: query,
  })
}
// 获取绑定信息
export function WeGetMyBindInfoPage(query) {
  return request({
    url: 'BindInfo/WeGetMyBindInfoPage',
    method: 'get',
    params: query,
  })
}
// 评价订单
export function WeEvaluateOrder(data) {
  return request({
    url: 'OrderEval/WeEvaluateOrder',
    method: 'post',
    data,
  })
}

// 获取促销活动列表-居民
export function WxGetPromotionPage(query) {
  return request({
    url: 'Promotion/WxGetPromotionPage',
    method: 'get',
    params: query,
  })
}

// 获取促销活动报名列表
export function WxGetMyActEnrolPage(query) {
  return request({
    url: 'Promotion/WxGetMyActEnrolPage',
    method: 'get',
    params: query,
  })
}

// 获取促销活动报名列表
export function WxGetActEnrolPage(query) {
  return request({
    url: 'Promotion/WxGetActEnrolPage',
    method: 'get',
    params: query,
  })
}
// 完成活动交易-商铺
export function WxFinishMyTransaction(data) {
  return request({
    url: 'Promotion/WxFinishMyTransaction',
    method: 'post',
    data,
  })
}
// 拒绝活动交易-商铺
export function WxRefuseMyTransaction(data) {
  return request({
    url: 'Promotion/WxRefuseMyTransaction',
    method: 'post',
    data,
  })
}
// 获取商铺订单列表，居民端商铺小程序使用
export function WxGetShopOrderPage(query) {
  return request({
    url: 'ShopOrder/WxGetShopOrderPage',
    method: 'get',
    params: query,
  })
}

/** 食堂订单 */
// 居民端-生成订单
export function WxGenerateOrder(data) {
  return request({
    url: 'MessOrder/WxGenerateOrder',
    method: 'post',
    data,
  })
}

// 提交订单
export function WxPlaceOrder(data) {
  return request({
    url: 'MessOrder/WxPlaceOrder',
    method: 'post',
    data,
  })
}

// 保存订单美食，居民端居民使用
export function WeSaveMessOrderService(data) {
  return request({
    url: 'MessOrder/WeSaveOrderService',
    method: 'post',
    data,
  })
}

// 删除订单美食，居民端居民使用
export function WeDeleteOrderFood(data) {
  return request({
    url: 'MessOrder/WeDeleteOrderFood',
    method: 'post',
    data,
  })
}
// 居民端-获取我的订单列表
export function WxGetMyOrderPage(query) {
  return request({
    url: 'MessOrder/WxGetMyOrderPage',
    method: 'get',
    params: query,
  })
}

// 获取订单美食列表，居安端居民使用
export function WeGetOrderFoodList(query) {
  return request({
    url: 'MessOrder/WeGetOrderFoodList',
    method: 'get',
    params: query,
  })
}

// 居民端-获取邻里中心列表页
export function WxGetNeInfoPage(query) {
  return request({
    url: 'NeInfo/WxGetNeInfoPage',
    method: 'get',
    params: query,
  })
}

// 居民端-预约申请
export function WxPreApply(data) {
  return request({
    url: 'PreBook/WxPreApply',
    method: 'post',
    data,
  })
}
